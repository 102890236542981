<template>
  <div class="modal fade" id="modal-add-image" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h4 class="text-success my-auto mb-3">
            Insert Image
          </h4>
          <div class="row">
            <div class="col">
              <button
                class="btn w-100"
                :class="
                  source === 'url' ? 'btn-success' : 'btn-outline-success'
                "
                @click.prevent="source = 'url'"
              >
                Image URL
              </button>
            </div>

            <div class="col">
              <button
                class="btn w-100"
                :class="
                  source === 'upload' ? 'btn-success' : 'btn-outline-success'
                "
                @click.prevent="source = 'upload'"
              >
                Upload Image
              </button>
            </div>

            <div class="col">
              <button
                class="btn w-100"
                :class="
                  source === 'gallery' ? 'btn-success' : 'btn-outline-success'
                "
                @click="getImages"
                @click.prevent="source = 'gallery'"
              >
                Image Gallery
              </button>
            </div>
          </div>

          <hr />

          <div v-if="source === 'url'">
            <input
              type="url"
              v-model="url"
              class="form-control mb-3"
              :placeholder="'Provide image URL...'"
            />
          </div>

          <div v-else-if="source === 'upload'">
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="uploadImage"
              class="form-control mb-3"
            />
          </div>

          <div v-else-if="source === 'gallery'" class="row">
            <ul class="gallery">
              <li v-for="i in images" v-bind:key="i.id" class="item">
                <img
                  v-bind:src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + i.path"
                  class="item-image" @click="selectGalleryImage" />
              </li>
              <li v-if="!images || !images.length" class="mb-2">
                No images found
              </li>
            </ul>

            <div class="gallery-nav mb-3">
              <div><a @click="galleryPrev" :class="!this.gallery_prev ? 'disabled' : ''">Prev</a></div>
              <div><a @click="galleryNext" :class="!this.gallery_next ? 'disabled' : ''">Next</a></div>
            </div>
          </div>

          <button
            class="btn btn-success float-end"
            v-if="source !== 'gallery'"
            :disabled="!url"
            @click="selectImage"
          >
            <span v-if="uploading">
              Uploading...
            </span>
            <span v-else>Insert Image</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      source: "url",
      url: "",
      uploading: false,
      images: null,
      gallery_next: null,
      gallery_prev: null
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
  },
  methods: {
    closeModal() {
      this.$emit("closed");
    },

    uploadImage(evt) {
      this.uploading = true;

      let formData = new FormData();
      formData.append("image", evt.target.files[0]);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/posts/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data }) => {
          this.url = 'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + data.path;
          this.uploading = false;
        });
    },

    selectImage() {
      const data = {
        src: this.url
      };

      this.$emit("inserted", data);
      this.closeModal();
    },

    selectGalleryImage(e) {
      this.url = e.target.src;
      this.selectImage();
    },

    getImages(e, url) {
      let imageUrl = url ? url : process.env.VUE_APP_API_URL + "/posts/images";

      this.$axios
        .get(imageUrl)
        .then(({ data }) => {
          this.images = data.data;
          this.gallery_next = data.next_page_url;
          this.gallery_prev = data.prev_page_url;
        });
    },

    galleryPrev(e) {
      if (this.gallery_prev) {
        this.getImages(e, this.gallery_prev);
      }
    },

    galleryNext(e) {
      if (this.gallery_next) {
        this.getImages(e, this.gallery_next);
      }
    }
  },
  mounted() {
    $("#modal-add-image").modal("show");
  },
  destroyed() {
    this.closeModal();
    this.$emit("cancelInsertImage");
  }
};
</script>

<style>
  .modal-dialog {
    max-width: 750px;
  }

  ul.gallery {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  li.item {
    width: 150px;
    height: 150px;
    flex-grow: 1;
  }

  li.item:last-child {
    flex-grow: 10;
  }

  img.item-image {
    width: 100%;
    height: 100%;
    padding: 1px;
    object-fit: cover;
  }

  .gallery-nav {
    display: flex;
    justify-content: space-between;
  }

  a.disabled {
    color: #888;
    text-decoration: none;
  }
</style>
