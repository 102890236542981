import { TextStyle } from "@tiptap/extension-text-style";
import { mergeAttributes } from "@tiptap/core";

export const Id = TextStyle.extend({
  name: "id",

  addOptions() {
    return {
      types: ["textStyle"],
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('id'),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            'id': attributes.id,
          }
        },
      },
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          id: {
            default: "none",

            renderHTML: (attributes) => {
              if (!attributes.id) {
                return {};
              }

              return {
                id: attributes.id
              };
            },

            parseHTML: (element) => ({
              id: element.id
            })
          }
        }
      }
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: element => {
          const hasId = typeof element.id != 'undefined';

          if (!hasId) {
            return false
          }

          return {
            id: element.id
          }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      ...this.parent?.(),

      setId: (id) => ({ chain }) => {
        return chain().setMark("textStyle", { id }).run();
      },

      unsetId: () => ({ chain }) => {
        return chain()
          .setMark("textStyle", { id: "" })
          .removeEmptyTextStyle()
          .run();
      }
    };
  }
});