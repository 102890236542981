<template>
  <div>
    <div class="upload" v-on:click="showModal" v-if="!image">
      <i class="far fa-plus upload-icon" />
      <template v-if="seen">
        <add-image-modal
          @inserted="insertImage"
          @closed="closeModal" />
      </template>
    </div>
    <div class="upload" v-if="image">
      <img v-bind:src="image" v-on:click.self="unsetFeaturedImg" class="form-control" />
    </div>
  </div>
</template>

<script>
import AddImageModal from './AddImageModal.vue';

export default {
  props: [ "image" ],
  data() {
    return {
      seen: false
    }
  },
  methods: {
    showModal() {
      $("#modal-add-image").modal("show");
      this.seen = true;
    },
    closeModal() {
      $("#modal-add-image").modal("hide");
      this.seen = false;
    },
    insertImage(data) {
      this.$emit('input', data.src);
      this.seen = false;
    },
    unsetFeaturedImg() {
      this.$emit('unsetFeaturedImg');
    }
  },
  components: {
    AddImageModal
  },
};
</script>

<style>
  .upload {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 125px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .upload:hover {
    border-color: #409eff;
  }

  .upload-icon {
    font-size: 2rem;
    color: #808a97;
  }

  .upload > img {
    max-width: 25%;
    border: 0px;
  }
</style>