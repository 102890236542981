<template>
  <div>
    <vue-tiptap-wysiwyg
        v-model="content"
        :class="classList"
        :input-type="inputType"
        :keyboard-submit="keyboardSubmit"
        :disableFullScreen = "disableFullScreen"
        @keyboardSubmit="$emit('keyboardSubmit')"
        required>
      <template v-slot:imageupload="scope">
        <add-image-modal
            @inserted="scope.insert"
            @closed="scope.cancel" />
      </template>
    </vue-tiptap-wysiwyg>
  </div>
</template>

<script>
import VueTiptapWysiwyg from "./vue-tiptap-wysiwyg/vue-tiptap-wysiwyg"
import AddImageModal from './partials/AddImageModal';

export default {
  props: ["value", "classList", "inputType", "keyboardSubmit", "disableFullScreen"],
  data() {
    return {
      content: this.value
    }
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content() {
      this.$emit("input", this.content)
    }
  },
  components: {
    VueTiptapWysiwyg,
    AddImageModal
  }
};
</script>

<style>
</style>
