import { Link } from "@tiptap/extension-link";

export const LinkTitle = Link.extend({
  name: "link",

  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {
        target: '_blank',
        title: null
      },
    }
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      title: {
        default: null
      }
    }
  },
});